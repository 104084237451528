<template>
  <!-- 划分 -->
  <div class="tab login__wrapper">
    <!-- 左侧图片 -->
    <div class="left">
      <img src="@/assets/denglu/login-bg.jpg" />
    </div>
    <!-- 表单控件 -->
    <div class="FormModel">
      <img src="@/assets/denglu/logo.png" />
      <p class="p">
        <b>账号登录</b>
      </p>
      <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            size="large"
            type="text"
            allow-clear
            placeholder="账号"
            v-decorator="[
              'username',
              {
                rules: [{ required: true, message: '请输入帐号' }, { validator: handleUsernameOrEmail }],
                validateTrigger: 'blur',
              },
            ]"
          ></a-input>
        </a-form-item>

        <a-form-item>
          <a-input-password
            allow-clear
            size="large"
            placeholder="密码"
            v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]"
          ></a-input-password>
        </a-form-item>
        <div class="login-tab">
          <a-form-item>
            <router-link :to="{ name: 'forget' }" class="forge-password" style="float: right">忘记密码?</router-link>
          </a-form-item>

          <a-form-item style="width: 204px; height: 40px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
            >确认登录</a-button
            >
          </a-form-item>
        </div>

        <div class="user-login-other">
          <router-link class="register" :to="{ name: 'register' }">
            暂无账号 ,
            <u>立即注册</u>
          </router-link>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      }
    }
  },
  created () {},
  methods: {
    ...mapActions(['Login', 'Logout', 'LoginSms', 'GetInfo']),
    // handler
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login,
        GetInfo
      } = this

      state.loginBtn = true

      const validateFieldsKey = ['username', 'password']

      validateFields(validateFieldsKey, { force: true }, async (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          delete loginParams.username
          loginParams[!state.loginType ? 'email' : 'username'] = values.username
          loginParams.password = values.password
          Login(loginParams)
            .then(() => {
              GetInfo()
                .then(({ data }) => {
                  this.loginSuccess(data)
                })
                .finally(() => {
                  state.loginBtn = false
                })
            })
            .catch(() => {
              state.loginBtn = false
            })
        } else {
          state.loginBtn = false
        }
      })
    },
    async loginSuccess (userInfo) {
      await this.$store.dispatch('GenerateRoutes')
      this.$router.push({
        name: 'home'
      })
      // 延迟 1 秒显示欢迎信息
      this.$notification.success({
        message: '欢迎',
        description: `${userInfo.username}，欢迎回来`
      })
      this.isLoginError = false
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  position: relative;
}
body {
  background: #f4f4f8;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: center;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(110, 84, 84, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      font-size: 16px;
      text-align: center;
    }
  }
}

.tab {
  width: 1100px;
  height: 610px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  .left img {
    width: 500px;
    height: 610px;
    float: left;
  }
  .FormModel {
    width: 600px;
    height: 610px;
    background: #ffffff;
    float: left;
    padding: 0 40px;
    border-radius: 0 8px 8px 0;
    img {
      display: block;
      width: 300px;
      height: 64.47px;
      margin: 33px auto 20px;
    }
  }
}
.p {
  width: 74px;
  height: 25px;
  font-size: 18px;
  margin-top: 40px;
  display: inline-block;
  line-height: 18px;
  color: #333333;
  opacity: 1;
}

.login-tab {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
</style>
<style></style>
